import React, { Component } from "react";
import "./about.scss";
import bc from "../../assets/BC.svg";
import medTiles from "../../assets/Frame.png";
import medTilesp from "../../assets/Frame.webp";
class About extends Component {

  render() {
    return (
      <div
        className="about"
        style={{
          height: "100%",
          width: "auto",
          marginTop: "115px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img src={bc} alt="BC" id="BCbg" />

        <h1>Body Contouring</h1>

 
        <div className="aboutTextOne">
          <p>
            The mightiest power is to be <span>confident and{" "}
            comfortable in YOUR skin.</span> That’s why we’re here!
          </p>
        </div>

        <div className="tileComb">
          <div className="firstrow">

            <div id="First">
              <div className="card" id="leftImg" ></div>
              <div id="firstTxt">Enjoy looking in the mirror<br/>every day.</div>


            </div>

            <div id="Second">
              <div className="card" id="midImg"></div>
              <div id="secondTxt"> We want <span>YOU</span> to: </div>
            </div>

          </div>
          <div className="secondrow">

            <div id="mFirst">
              <div className="card" id="MleftImg"></div>
            </div>

            <div id="mMid">
              <div className="card" id="MmidImg"></div>
              <div id="fourthTxt">Fall in love with<br/>your beautiful bodies.</div>
            </div>

            <div id="mSecond">
              <div className="card" id="MrightImg"></div>
              <div id="thirdTxt">Rock every piece of<br/>apparel you wear.</div>

            </div>


          </div>
          <div className="thirdrow">

            <div id="bFirst">
              <div className="card" id="BmidImg"></div>
              <div id="sixthTxt">Emit the confidence<br/>of a red carpet event</div>
            </div>

            <div id="bSecond">
              <div className="card" id="BrightImg"></div>  
              <div id="fifthTxt">Feel comfortable and<br/>confident in your skin.</div>
        
            </div>

          </div>
        </div>

        <div id="tileCombM">
          <picture>
            <source srcSet={medTilesp} type="image/webp" />
          <img id="medTiles" src={medTiles} alt="tilestiles"></img>
          </picture>
        </div>

        <div className="aboutTextTwo">
          <p>
            So, if contouring your body helps <span>YOU</span> become confident,
            and makes you feel good about <span>yourself</span>, then we are
            here to help you with it.
          </p>
          <br />
          <p>
            We sculpt <b>tummies, buttocks, arms, chins, and love handles.</b>
          </p>
          <br />
          <p>
            But that’s just the cream of what we <span>REALLY</span> do. Really.
          </p>
        </div>

        <a href="/services">
          <div id="servBtn">
            <span>Go to Services</span>
          </div>
        </a>

        <div className="abtCards">
          <div className="abtTop">
            <h2>What is the Body Contouring experience?</h2>
          </div>
          {/* <div className="quadrant">
            <img src={quad} alt="quad" style={{transform:"rotate(270deg)"}}/>
            <div className="quadRight">
              <img src={quadR} alt="quad" />
            </div>
            <img src={quad} alt="quad" />
            <div className="quadRight">
              <img src={quadD} alt="quad" />
            </div>
          </div> */}

          <div className="abtBottom">
          
            
            <h3>We're all about <span>QUALITY</span></h3>
            <p>
              When it comes to your body, we wouldn’t compromise at all. So, we
              got the best technology and professionals to provide you with a
              comfortable experience.
            </p>
            <h3>
              Providing services that suit <span>YOU</span>
            </h3>
            <p>
              One shoe doesn’t fit all, and that’s why we offer customized service
              packages that can suit your needs.
            </p>
            <h3>No room for <span>JUDGEMENT</span></h3>
            <p>
              We’re a body positive space and all our services aim to
              make you feel good about yourself.
            </p>
            <h3>Feeling <span>CONFIDENT</span></h3>
            <p>
              Walk out of our space celebrating and loving your body more than
              ever!
            </p>

          </div>
        </div>
        <div className="abtCards">
          <div className="abtTop">
            <h2>About Cryolipolysis or Fat Freezing</h2>
          </div>
          {/* <div className="quadrant">
            <img src={quad} alt="quad" style={{transform:"rotate(270deg)"}}/>
            <div clasNames="quadRight">
              <img src={quadR} alt="quad" />
            </div>
            <img src={quad} alt="quad" 
          </div> */}

          <div className="abtBottom">
           
            <h3>How was cryolipolysis discovered?</h3>
            <p>
              Back in the 1970s, a scientist noticed a 6-month-old suck on an
              ice lolly. The kid’s cheek had swollen and become red. The ice
              lolly had caused the death of the fat cells in the cheeks, making
              it seem like they had sores.
              <br />
              Years later, Dr Rox Anderson and Dr Dieter Manstein applied this
              principle in cosmetics and developed the cryolipolysis procedure.
            </p>

            <h3>How is cryolipolysis done?</h3>
            <p>
              Cryolipolysis is a low-risk, non-invasive procedure that uses a
              vacuum to cool parts of your body to as low as -11 degree celsius.
              <br />
              This procedure is done for 30 to 60 minutes per session. The cold
              temperature helps kill almost 20% of the fat cells in the
              particular area every time.
              <br />
              <br />

              <span id="lasttext">It’s essential to remember that this procedure is not for weight
              reduction, but only for fat loss.</span>
            </p>
          </div>
        </div>

        <div className="bottomBtns">
          <a href="/faq">
            <div id="leftBtn">
              <span>Go to FAQs</span>
            </div>
          </a>
          <a href="/">
            <div id="rightBtn">
              <span>Home</span>
            </div>
          </a>
        </div>
      </div>
    );
  }
}
export default About;
