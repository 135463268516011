import React from 'react';
import styles from './Testimonial.module.css';
import Slider from 'react-slick';
import { FaLongArrowAltLeft, FaLongArrowAltRight } from 'react-icons/fa';

import customer from '../../assets/customer.png';

const Testimonial = () => { 
  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return(
        <FaLongArrowAltRight
            className={className}
            style={{ ...style, top:'110%', right:'45%', zIndex: 1, transform: 'scale(2)' }}
            onClick={onClick}
            color='#8D3A56' />
    );
  };

  const PrevArrow = (props) => {
      const { className, style, onClick } = props;
      return(
          <FaLongArrowAltLeft
              className={className}
              style={{ ...style, top:'110%', left: '45%', zIndex: 1, transform: 'scale(2)' }}
              onClick={onClick}
              color='#8D3A56' />
      );
  };

  const settings = {
      dots: false,
      infinite: true,
    //   autoplay: true,
    //   speed: 5000,
    //   autoplaySpeed: 5000,
      slidesToShow: 3,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 571,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  }
  
  return( 
    <div className={`${styles.testimonialBody}`}> 
      <div className={`${styles.headingSection} container`}>
        <h1>Testimonials</h1>
      </div>
      <div className={`${styles.mainSection} container`}>
        <Slider {...settings}>
            <div className={`${styles.testimonials} card`}>
                <div className='card-body p-4'>
                    <p>“The staff was lovely and professional. Made me feel like I was in safe hands. Guided me through the process like a true professional. Would recommend the treatment. Can’t wait to come back!”</p>
                    <h4>Samantha</h4>
                    <p>Customer</p>
                </div>
            </div>
            <div className={`${styles.testimonials} card`}>
                <div className='card-body p-4'>
                    <p>“Thank you! You all made me feel super comfotable and you explained everything very professionally. I felt really safe in your hands and I can already see dramitic results after 2 sessions!”</p>
                    <h4>Natasha</h4>
                    <p>Customer</p>
                </div>
            </div>
            <div className={`${styles.testimonials} card`}>
                <div className='card-body p-4'>
                    <p>“Thank you, I felt really safe during the entire procedure”</p>
                    <h4>Emily</h4>
                    <p>Customer</p>
                </div>
            </div>
            <div className={`${styles.testimonials} card`}>
                <div className='card-body p-4'>
                    <p>“I can see dramatic results already!”</p>
                    <h4>Jena</h4>
                    <p>Customer</p>
                </div>
            </div>
        </Slider>
      </div>
    </div> 
  ) 
}

export default Testimonial;