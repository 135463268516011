import { Component } from "react";
import "../Services/services.scss";
import combined from "../../assets/combinednew.png";
import combinedp from "../../assets/combinednew.webp";
import elu from "../../assets/ellipseUp.svg";
import leftEli from "../../assets/leftEli.svg";
import rightEli from "../../assets/rightEli.svg";
import membership from '../../assets/memberLogo.svg';

class Services extends Component {
  componentDidMount() {
    const imageTwo = document.getElementById("imageTwo");
    const switchImg = document.getElementById("switchImg");

    window.onscroll = () => {
      if (window.scrollY >= 450) {
        switchImg.style.opacity = 1;
        imageTwo.style.opacity = 0;

      } else {
        // imageTwo.classList.remove("switchImg");
        imageTwo.style.opacity = 1;
        switchImg.style.opacity = 0;

      }
    };

  }

  mouseOver(e) {
    if (e.target.className === "onpt" || e.target.className === "pointers") {
      var chin = document.getElementById("onpt");
      chin.style.backgroundColor = "#8D3A56";
      chin.style.color = "white";
    } else if (e.target.className === "twpt") {
      var upd = document.getElementById("twpt");
      upd.style.backgroundColor = "#8D3A56";
      upd.style.color = "white";
    } else if (e.target.className === "thpt") {
      var lod = document.getElementById("thpt");
      lod.style.backgroundColor = "#8D3A56";
      lod.style.color = "white";
    } else if (e.target.className === "fopt") {
      var lh = document.getElementById("fopt");
      lh.style.backgroundColor = "#8D3A56";
      lh.style.color = "white";
    } else if (e.target.className === "fipt") {
      var abs = document.getElementById("fipt");
      abs.style.backgroundColor = "#8D3A56";
      abs.style.color = "white";
    } else if (e.target.className === "sipt") {
      var int = document.getElementById("sipt");
      int.style.backgroundColor = "#8D3A56";
      int.style.color = "white";
    } else if (e.target.className === "sept") {
      var out = document.getElementById("sept");
      out.style.backgroundColor = "#8D3A56";
      out.style.color = "white";
    } else if (e.target.className === "eipt") {
      var arms = document.getElementById("eipt");
      arms.style.backgroundColor = "#8D3A56";
      arms.style.color = "white";
    } else if (e.target.className === "nipt") {
      var butt = document.getElementById("nipt");
      butt.style.backgroundColor = "#8D3A56";
      butt.style.color = "white";
    } else if (e.target.className === "tept") {
      var roll = document.getElementById("tept");
      roll.style.backgroundColor = "#8D3A56";
      roll.style.color = "white";
    } else if (e.target.className === "elpt") {
      var bra = document.getElementById("elpt");
      bra.style.backgroundColor = "#8D3A56";
      bra.style.color = "white";
    } else if (e.target.className === "twept") {
      var flanks = document.getElementById("twept");
      flanks.style.backgroundColor = "#8D3A56";
      flanks.style.color = "white";
    } else if (e.target.className === "thipt") {
      var calves = document.getElementById("thipt");
      calves.style.backgroundColor = "#8D3A56";
      calves.style.color = "white";
    } 
  }

  mouseOut(e) {
    var chin = document.getElementById("onpt");
    var upd = document.getElementById("twpt");
    var lod = document.getElementById("thpt");
    var lh = document.getElementById("fopt");
    var abs = document.getElementById("fipt");
    var int = document.getElementById("sipt");
    var out = document.getElementById("sept");
    var arms = document.getElementById("eipt");
    var butt = document.getElementById("nipt");
    var roll = document.getElementById("tept");
    var bra = document.getElementById("elpt");
    var flanks = document.getElementById("twept");
    var calves = document.getElementById("thipt");

    chin.style.backgroundColor = "";
    chin.style.color = "";

    upd.style.backgroundColor = "";
    upd.style.color = "";

    lod.style.backgroundColor = "";
    lod.style.color = "";

    lh.style.backgroundColor = "";
    lh.style.color = "";

    abs.style.backgroundColor = "";
    abs.style.color = "";

    int.style.backgroundColor = "";
    int.style.color = "";

    out.style.backgroundColor = "";
    out.style.color = "";

    arms.style.backgroundColor = "";
    arms.style.color = "";

    butt.style.backgroundColor = "";
    butt.style.color = "";

    roll.style.backgroundColor = "";
    roll.style.color = "";

    bra.style.backgroundColor = "";
    bra.style.color = "";

    flanks.style.backgroundColor = "";
    flanks.style.color = "";

    calves.style.backgroundColor = "";
    calves.style.color = "";
    
  }

// zoneSelected(e){

//   var zOne = document.getElementById("zoneOne");
//   var zTwo = document.getElementById("zoneTwo");
//   var zThree = document.getElementById("zoneThree");
  
//   var firstList = document.querySelectorAll('.firstLi');
//   var secondList = document.querySelectorAll('.secondLi');
//   var thirdList = document.querySelectorAll('.thirdLi');


//   if(e.target.id === "zoneOne"){
//     zOne.classList.add('selectedZone');
//     zTwo.classList.remove('selectedZone');
//     zThree.classList.remove('selectedZone');

//     // firstList.classList.add('showList');
//     // firstList.classList.remove('hideList');

//     // secondList.classList.add('hideList');
//     // secondList.classList.remove('showList');

//     // thirdList.classList.add('hideList');
//     // thirdList.classList.remove('showList');
      
//     if(firstList.length > 0){
//       firstList[0].classList.remove('hideList');
//       firstList[1].classList.remove('hideList');
//       firstList[2].classList.remove('hideList');
//       firstList[0].classList.add('showList');
//       firstList[1].classList.add('showList');
//       firstList[2].classList.add('showList');

//     }
//     if(secondList.length > 0){
//       secondList[0].classList.add('hideList');
//       secondList[0].classList.remove('showList');
//       secondList[1].classList.add('hideList');
//       secondList[1].classList.remove('showList');
//       secondList[2].classList.add('hideList');
//       secondList[2].classList.remove('showList');

//     }
//     if(thirdList.length > 0){
//       thirdList[0].classList.add('hideList');
//       thirdList[0].classList.remove('showList');
//       thirdList[1].classList.add('hideList');
//       thirdList[1].classList.remove('showList');
//       thirdList[2].classList.add('hideList');
//       thirdList[2].classList.remove('showList');

//     }


//   } 
//   else if(e.target.id === "zoneTwo"){
//     zOne.classList.remove('selectedZone');
//     zTwo.classList.add('selectedZone');
//     zThree.classList.remove('selectedZone');

//     // firstList.classList.remove('showList');
//     // firstList.classList.add('hideList');

//     // secondList.classList.remove('hideList');
//     // secondList.classList.add('showList');

//     // thirdList.classList.add('hideList');
//     // thirdList.classList.remove('showList');

//     if(firstList.length > 0){
//       firstList[1].classList.add('hideList');
//       firstList[1].classList.remove('showList');
//       firstList[0].classList.add('hideList');
//       firstList[0].classList.remove('showList');
//       firstList[2].classList.add('hideList');
//       firstList[2].classList.remove('showList');

//     }
//     if(secondList.length > 0){
//       secondList[1].classList.remove('hideList');
//       secondList[1].classList.add('showList');
//       secondList[0].classList.remove('hideList');
//       secondList[0].classList.add('showList');
//       secondList[2].classList.remove('hideList');
//       secondList[2].classList.add('showList');

//     }
//     if(thirdList.length > 0){
//       thirdList[1].classList.add('hideList');
//       thirdList[1].classList.remove('showList');
//       thirdList[0].classList.add('hideList');
//       thirdList[0].classList.remove('showList');
//       thirdList[2].classList.add('hideList');
//       thirdList[2].classList.remove('showList');

//     }





//   }
//   else if(e.target.id === "zoneThree"){
//     zOne.classList.remove('selectedZone');
//     zTwo.classList.remove('selectedZone');
//     zThree.classList.add('selectedZone');

//     // firstList.classList.remove('showList');
//     // firstList.classList.add('hideList');

//     // secondList.classList.add('hideList');
//     // secondList.classList.remove('showList');

//     // thirdList.classList.remove('hideList');
//     // thirdList.classList.add('showList');

//     if(firstList.length > 0){
//       firstList[2].classList.add('hideList');
//       firstList[2].classList.remove('showList');
//       firstList[1].classList.add('hideList');
//       firstList[1].classList.remove('showList');
//       firstList[0].classList.add('hideList');
//       firstList[0].classList.remove('showList');

//     }
//     if(secondList.length > 0){
//       secondList[2].classList.add('hideList');
//       secondList[2].classList.remove('showList');
//       secondList[1].classList.add('hideList');
//       secondList[1].classList.remove('showList');
//       secondList[0].classList.add('hideList');
//       secondList[0].classList.remove('showList');

//     }
//     if(thirdList.length > 0){
//       thirdList[2].classList.remove('hideList');
//       thirdList[2].classList.add('showList');
//       thirdList[1].classList.remove('hideList');
//       thirdList[1].classList.add('showList');
//       thirdList[0].classList.remove('hideList');
//       thirdList[0].classList.add('showList');

//     }


//   }
//   else {
//     zOne.classList.add('selectedZone');
//     zTwo.classList.remove('selectedZone');
//     zThree.classList.remove('selectedZone');

//     if(firstList.length > 0){
//       firstList[0].classList.remove('hideList');
//       firstList[1].classList.remove('hideList');
//       firstList[2].classList.remove('hideList');
//       firstList[0].classList.add('showList');
//       firstList[1].classList.add('showList');
//       firstList[2].classList.add('showList');

//     }
//     if(secondList.length > 0){
//       secondList[0].classList.add('hideList');
//       secondList[0].classList.remove('showList');
//       secondList[1].classList.add('hideList');
//       secondList[1].classList.remove('showList');
//       secondList[2].classList.add('hideList');
//       secondList[2].classList.remove('showList');

//     }
//     if(thirdList.length > 0){
//       thirdList[0].classList.add('hideList');
//       thirdList[0].classList.remove('showList');
//       thirdList[1].classList.add('hideList');
//       thirdList[1].classList.remove('showList');
//       thirdList[2].classList.add('hideList');
//       thirdList[2].classList.remove('showList');

//     }

//   }

// }

// seshSelected(e){

//   var sOne = document.getElementById("seshOne");
//   var sTwo = document.getElementById("seshTwo");
//   var sThree = document.getElementById("seshThree");
  
//   if(e.target.id === "seshOne"){
//     sOne.classList.add('selectedZone');
//     sTwo.classList.remove('selectedZone');
//     sThree.classList.remove('selectedZone');


//   } 
//   else if(e.target.id === "seshTwo"){
//     sOne.classList.remove('selectedZone');
//     sTwo.classList.add('selectedZone');
//     sThree.classList.remove('selectedZone');

//   }
//   else if(e.target.id === "seshThree"){
//     sOne.classList.remove('selectedZone');
//     sTwo.classList.remove('selectedZone');
//     sThree.classList.add('selectedZone');

//   }
//   else {
//     sOne.classList.add('selectedZone');
//     sTwo.classList.remove('selectedZone');
//     sThree.classList.remove('selectedZone');

//   }

// }

  render() {
    return (
      <div className="services">
        <div className="bgAnim">
          <img
            src={elu}
            alt="up"
            height="900px"
            width="100%"
            id="imageOne"
            data-aos="animateDown"
            data-aos-anchor="#pkgAnchor"
            data-aos-offset="-100"

            data-aos-duration="500"
            data-aos-easing="ease-in-out-quart"
          />
          <div id="imageTwo" className="fade-img"></div>
          <div id="switchImg" className="fade-img"></div>

          <img
            src={elu}
            alt="up"
            height="900px"
            width="100%"
            id="imageThree"
            data-aos="animateUp"
            data-aos-anchor="#pkgAnchor"
            data-aos-offset="-100"

            data-aos-duration="500"
            data-aos-easing="ease-in-out-quart"
          />
          
          

        </div>


        <div className="servicesWrapper">
          <h2>Services</h2>

          <div className="servicesCont">
            <div className="servicesTitle">

              <div className="leftSer">
                <ul>
                  <li
                    className="onpt"
                    onMouseOver={this.mouseOver}
                    onMouseLeave={this.mouseOut}
                  > 
                    <span>1</span>Chin
                    
                  </li>
                  <li
                    className="twpt"
                    onMouseOver={this.mouseOver}
                    onMouseLeave={this.mouseOut}
                  >
                    <span>2</span>Upper Abdomen

                  </li>
                  <li
                    className="thpt"
                    onMouseOver={this.mouseOver}
                    onMouseLeave={this.mouseOut}
                  >
                    <span>3</span>Lower Abdomen
                  </li>
                  <li
                    className="fopt"
                    onMouseOver={this.mouseOver}
                    onMouseLeave={this.mouseOut}
                  >
                    <span>4</span>Love Handles
                  </li>
                  <li
                    className="fipt"
                    onMouseOver={this.mouseOver}
                    onMouseLeave={this.mouseOut}
                  >
                    <span>5</span>Abs
                  </li>
                  <li
                    className="sipt"
                    onMouseOver={this.mouseOver}
                    onMouseLeave={this.mouseOut}
                  >
                    <span>6</span>Inner Thighs
                  </li>
                  <li
                    className="sept"
                    onMouseOver={this.mouseOver}
                    onMouseLeave={this.mouseOut}
                  >
                    <span>7</span>Outer Thighs
                  </li>
                  <li
                    className="eipt"
                    onMouseOver={this.mouseOver}
                    onMouseLeave={this.mouseOut}
                  >
                    <span>8</span>Arms
                  </li>
                  <li
                    className="nipt"
                    onMouseOver={this.mouseOver}
                    onMouseLeave={this.mouseOut}
                  >
                    <span>9</span>Buttocks
                  </li>
                  <li
                    className="tept"
                    onMouseOver={this.mouseOver}
                    onMouseLeave={this.mouseOut}
                  >
                    <span>10</span>Banana Rolls
                  </li>
                  <li
                    className="elpt"
                    onMouseOver={this.mouseOver}
                    onMouseLeave={this.mouseOut}
                  >
                    <span>11</span>Bra Straps
                  </li>
                  <li
                    className="twept"
                    onMouseOver={this.mouseOver}
                    onMouseLeave={this.mouseOut}
                  >
                    <span>12</span>Flanks
                  </li>
                  <li
                    className="thipt"
                    onMouseOver={this.mouseOver}
                    onMouseLeave={this.mouseOut}
                  >
                    <span>13</span>Calves
                  </li>
                </ul>
              </div>
            </div>

            <div className="rightSer">

              <div id="modelImages">
                <picture>
                  <source srcSet={combinedp} type="image/webp" />
                <img src={combined} alt="combined" height="500px" id="frontImg"/>
                </picture>
              </div>

              <div id="pointers">
                <span id="onpt" className="pointers onpt">
                  1
                </span>
                <span id="twpt" className="pointers twpt">
                  2
                </span>
                <span id="thpt" className="pointers thpt">
                  3
                </span>
                <span id="fopt" className="pointers fopt">
                  4
                </span>
                <span id="fipt" className="pointers fipt">
                  5
                </span>
                <span id="sipt" className="pointers sipt">
                  6
                </span>
                <span id="sept" className="pointers sept">
                  7
                </span>
                <span id="eipt" className="pointers eipt">
                  8
                </span>
                <span id="nipt" className="pointers nipt">
                  9
                </span>
                <span id="tept" className="pointers tept">
                  10
                </span>
                <span id="elpt" className="pointers elpt">
                  11
                </span>
                <span id="twept" className="pointers twept">
                  12
                </span>
                <span id="thipt" className="pointers thipt">
                  13
                </span>
              </div>
            </div>
          </div>
        </div>
        <a href="https://www.fresha.com/book-now/body-contouring-zuy26omc/all-offer?pId=659672">
          <div className="bookpkgBtn">
            <span>Buy Now</span>
          </div>
        </a>
        <div id="pkgAnchor" className="packagesWrapper">
          <div className="packagesCont">
            <div className="packageTitle">
              <h2>Packages</h2>
            </div>
            <div className="packages">
              <div id="pkgO">
                <div className="topCover">
                  <div className="top">
                    {" "}
                    <h2>1 Zone</h2>
                  </div>
                </div>
                <div className="mid">
                  <h1>LOWER ABS</h1>
                  <hr className="packagesHrL"/>
                  <h1>UPPER ABS</h1>
                  <hr className="packagesHrL"/>
                  <h1>CHIN</h1>
                </div>
               
              </div>
              <div id="pkgT">
                <div className="topCover">
                  <div className="top">
                    {" "}
                    <h2>2 Zones</h2>
                  </div>
                </div>
                <div className="mid">
                  <h1>ARMS</h1>
                  <hr className="packagesHrL"/>

                  <h1>INNER THIGHS</h1>
                  <hr className="packagesHrL"/>

                  <h1>OUTER THIGHS</h1>
                  <hr className="packagesHrL"/>

                  <h1>BANANA ROLLS</h1>
                  <hr className="packagesHrL"/>

                  <h1>BRA STRAPS</h1>
                  <hr className="packagesHrL"/>

                  <h1>BUTTOCKS</h1>
                  <hr className="packagesHrL"/>

                  <h1>FLANKS</h1>
                  <hr className="packagesHrL"/>

                  <h1>CALVES</h1>

                </div>
                
              </div>
       
            </div>
            <div id="zones">
       
              <div className="zones">
                  <h1> </h1>
                  <h3 className="sesh">3 Sessions</h3>
                  <h3 className="sesh">5 Sessions</h3> 
                  <h3 className="sesh">10 Sessions</h3>
                  <h3 className="zonesTxt">1 ZONE</h3>
                  <h3 className="pricesTxt">$299</h3>
                  <h3 className="pricesTxt">$489</h3>
                  <h3 className="pricesTxt" style={{fontWeight: '600'}}>$969</h3>
                  <h3 className="zonesTxt">2 ZONES</h3>
                  <h3 className="pricesTxt">$449</h3>
                  <h3 className="pricesTxt">$749</h3>
                  <h3 className="pricesTxt" style={{fontWeight: '600'}}>$1449</h3>
                  <h3 className="zonesTxt">3 ZONES</h3>
                  <h3 className="pricesTxt">$589</h3>
                  <h3 className="pricesTxt">$989</h3>
                  <h3 className="pricesTxt" style={{fontWeight: '600'}}>$1979</h3>

                </div> 
              <div id="distxt">Trial sessions at $99 per zone. <span><a href="tel:+61404616832">Get in touch</a></span></div>
            </div>
            <a href="https://www.fresha.com/book-now/body-contouring-zuy26omc/all-offer?pId=659672">
                <div className="bookpkgBtn">
                  <span>Book Now</span>
                </div>
              </a>
              
          </div>
          
        </div>
        
        <div id="bkbtnBg">
          <div id="leftSide">
            <img src={membership} alt="membership" height="80px" width="auto" ></img>
            <p>MEMBERSHIP</p>
            <p>$95/WEEK [3 MONTHS MINIMUM]</p>
          </div>
          <div id="rightSide">
            <a href="https://www.fresha.com/book-now/body-contouring-zuy26omc/all-offer?pId=659672">
              <div id="booknowBtn">
                <span>Buy Now</span>
              </div>
            </a>
          </div>
        
        </div>


        {/* <div id="zonesNew">
            <div id="zonesWrapper">
              <div id="topRow">
                <table style={{width:'100%'}}>
                  <tr>
                    <td id="zoneOne" className="selectedZone" onClick={this.zoneSelected}>1 Zone</td>
                    <td id="zoneTwo" className='' onClick={this.zoneSelected}>2 Zone</td>
                    <td id="zoneThree" className='' onClick={this.zoneSelected}>3 Zone</td>
                  </tr>
                </table>
              </div>

              <div className="info">
                <h5>What You'll Get</h5>
                <ZonesData />
              </div>
              <div className="seshRow">
                <table style={{width:'100%'}}>
                    <tr>
                      <td id="seshOne" className="selectedZone" onClick={this.seshSelected}>3 SESSIONS</td>
                      <td id="seshTwo" className='' onClick={this.seshSelected}>5 SESSIONS</td>
                      <td id="seshThree" className='' onClick={this.seshSelected}>10 SESSIONS</td>
                    </tr>
                </table>
              </div>

              <div className="pricesRow"><h3>$480<span>/ $99 per session</span></h3></div>
              <a id='zonesBookBtn' href="/"><div>Book Now</div></a>
            </div>
      </div> */}
            


        <div className="semiCircles">
          <div className="right">
            <img src={rightEli} alt="rightEli"></img>
          </div>
          <div className="left">
            <img src={leftEli} alt="leftEli"></img>
          </div>
         
        </div>


        <div id="steps">
        <div className='stepsHeader'>The Process
            {/* <img src={Rarrow} alt="rightArrow"/> */}
        </div>
        <div className="stepsWrapper">
          <div className="stepsCont" data-aos="fade-up">
            <div className="stepsTitle">Step 1.</div>
            <div className="stepsTxt">
              We start by measuring your target areas and taking “before” photos. It allows us to track your  progression over the coming weeks and months.
            </div>
          </div>

            <div className="stepsCont" data-aos="fade-up">
              <div className="stepsTitle">Step 2.</div>
              <div className="stepsTxt">
                We will then place an anti-freeze membrane on your chosen target
                area to stop any freeze burns to the skin. The applicators are
                then placed on top of the anti-freeze membrane.
              </div>
            </div>

          <div className="stepsCont" data-aos="fade-up">
            <div className="stepsTitle">Step 3.</div>
            <div className="stepsTxt">
              Sit back and relax for 45-60 minutes while the machine works its
              magic to crystallise your fat cells.
            </div>
          </div>

          {/* <div className="stepsRight"> */}
            <div className="stepsCont" data-aos="fade-up">
              <div className="stepsTitle">Step 4.</div>
              <div className="stepsTxt">
                After your treatment is complete we will remove both the
                anti-freeze membrane and applicators before massaging the area.
              </div>
            </div>
          {/* </div> */}

          <div className="stepsCont" data-aos="fade-up">
            <div className="stepsTitle">Step 5.</div>
            <div className="stepsTxt">
              After treatment, we recommend exercising for at least 30 minutes and drinking plenty of water for the best results. 
            </div>
          </div>
        </div>
    </div>
    
    <a href="/faq">
      <div id="faqsBtn">
        <span>Got Questions?</span>
      </div>
    </a>
    </div>
    );
  }
}

export default Services;
