import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h2>Privacy Policy</h2>
      <p>
        This privacy policy describes how Body Contouring AU ("we," "us," or
        "our") collects, uses, and discloses your personal information when you
        use our website at bodycontouring.au (the "Site").
      </p>
      <h3>Information We Collect</h3>
      <p>We collect several types of information from and about users of our Site, including:</p>
      <ul>
        <li>Personal Information: This can include your name, email address, phone number, and mailing address.</li>
        <li>Health Information: We may collect health information from you in the course of providing you with services or consultations. This information may include your medical history, weight, and body measurements.</li>
      </ul>
      <h3>How We Use Your Information</h3>
      <p>We use the information we collect to provide, operate, and maintain our Site; process your requests and inquiries; send you marketing communications; and comply with legal and regulatory requirements.</p>
      <h3>Sharing Your Information</h3>
      <p>We may share your information with third-party service providers who help us operate our business and provide our services. We will only share your information with these providers to the extent necessary for them to perform their services.</p>
      <h3>Security</h3>
      <p>We take reasonable steps to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no internet transmission is completely secure.</p>
      <h3>Your Rights</h3>
      <p>You have certain rights regarding your personal information. You may request access to your personal information, rectification of inaccurate information, and deletion of your personal information.</p>
      <h3>Changes to This Privacy Policy</h3>
      <p>We may update this privacy policy from time to time. We will post any changes on our Site.</p>
      <h3>Contact Us</h3>
      <p>If you have any questions about this privacy policy, please contact us at <a href="mailto:bodycontouring.au@gmail.com">bodycontouring.au@gmail.com</a> or <a href='tel: +61404616832'>+61404616832</a>.</p>
    </div>
  );
};

export default PrivacyPolicy;
