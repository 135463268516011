import Maincomponent from "./Components/MainComp/MainComp";

function App() {
  return (
  
    <Maincomponent></Maincomponent>
  
  );
}

export default App;
