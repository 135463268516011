import React from 'react';
import './landing.scss';
import homeImg from '../../assets/homeImg.png';
import testp from '../../assets/homeImg.webp';
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import testC from '../../assets/testimonials.png';
import testCp from '../../assets/testimonials.webp';
import servC from '../../assets/services.png';
import servCp from '../../assets/services.webp';
import bookC from '../../assets/book_now.png';
import bookCp from '../../assets/book_now.webp';
import Testimonial from '../Testimonial/Testimonial';
// import { BrowserRouter as Link } from "react-router-dom";

export default function Landing() {
  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    className: 'row row-cols-3 g-4'
  };

  return (
    <>
    <div id="topSec" style={{ height:"100%", width:"auto", marginTop:"105px", display:"flex", justifyContent:"center"}} >
            <picture>
                <source srcSet={testp} type="image/webp" />
          <img id="homeImg" src={homeImg} alt=""/>
          </picture>
      <div className="imgTxt">
        <div><h1 data-aos="fade-down" data-aos-duration="2000" id="bodyTxt">Body</h1><h1 data-aos="fade-up" data-aos-duration="2000" id="contTxt">Contouring</h1></div>
        <a href="https://www.fresha.com/book-now/body-contouring-zuy26omc/all-offer?pId=659672">
          <div id="consultBtnOne"> <div className="consultTxt"><b>BOOK NOW</b></div></div>
        </a>
      </div>
    </div>
    <div id="midSecOne">
      <div className="aText" style={{marginRight:"0.2em"}} data-aos="fade-right" data-aos-easing="linear" >Bold.</div>
      <div className="aText" data-aos="fade-right" data-aos-easing="linear" data-aos-delay="600">Beautiful.</div>
    </div>

    <div className="introText">
      <div style={{fontSize:"28px"}}><span style={{color:"#8D3A56", fontWeight:'600'}}>Body Contouring</span> is a hidden gem situated in Malvern East. Founded in 2021, our goal is to offer affordably-priced and specialised fat-freezing treatments, combined with <span style={{color:"#8D3A56", fontWeight:'600'}}> AMAZING </span> service!</div>
    </div>

  <div className="cards">
    <div className="cardsWrapper">
      <a href="/services"> 
        <picture>
            <source srcSet={servCp} type="image/webp" />
            <img src={servC} alt="services" />
        </picture>
      </a>


      <a href="https://www.fresha.com/book-now/body-contouring-zuy26omc/all-offer?pId=659672"> 
    <picture>
            <source srcSet={bookCp} type="image/webp" />
          <img src={bookC} alt="booknow"/>
    </picture>
      </a>
      <a href="#sliderWrapper"> 
          <picture>
            <source srcSet={testCp} type="image/webp" />
          <img src={testC} alt="testimonials"/>
          </picture>
      </a>
    </div>
  </div>

  <a href="https://www.fresha.com/book-now/body-contouring-zuy26omc/all-offer?pId=659672">
    <div id="consultBtn"> <div className="consultTxt"><b>BOOK A FREE CONSULTATION</b></div></div>
  </a>
  <div class="product_section">
  <div class="content">
    <div class="page">
      <center><h2>Ravel by the numbers</h2></center>
      <div class="text">
        <div class="item">
          <h2>98%</h2>
          <span>Of our customers are struggling with stubborn fat</span>
        </div>
        <div class="item">
          <h2>15 Days</h2>
          <span>days is the average time it takes to start seeing a difference in your body</span>
        </div>
        <div class="item">
          <h2>80%</h2>
          <span>Of our customers see a difference of at least half an inch from a single session</span>
        </div>
        <div class="item">
          <h2>250+</h2>
          <span>Customers who have tried and have seen positive results at body contouring au</span>
        </div>
        <div class="item">
          <h2>40%</h2>
          <span>of our clients are men, which means our treatments are inclusive</span>
        </div>
        <div class="item">
          <h2>100+</h2>
          <span>5 star reviews</span>
        </div>
      </div>
    </div>
  </div>      
      <center> <p class="link"><a href="/pages/make-order" class="view">Take the quiz</a>
      </p> 
      </center>
</div>

  

  {/* <div id='testmonials'>

  <div className="testimTitle">
      <h2>Testimonials</h2>
  </div>

  <div id="sliderWrapper">
    <Slider {...settings} >
      <div className="slickCont col">
        <h3>
          "The staff was lovely and professional. Made me feel like I was in safe hands. Guided me through the process like a true professional. Would recommend the treatment. Can’t wait to come back!"</h3>
        <h5>- Samantha</h5>
      </div>
      <div className="slickCont col">
        <h3>
          "Thank you! You all made me feel super comfotable and you explained everything very professionally. I felt really safe in your hands and I can already see dramitic results after 2 sessions!"</h3>
        <h5>- Natasha</h5>
      </div>
      <div className="slickCont col">
        <h3>
          "Thank you, I felt really safe during the entire procedure"</h3>
        <h5>- Emily</h5>
      </div>
      <div className="slickCont col">
        <h3>
          "I can already see dramatic results already!"</h3>
        <h5>- Jena</h5>
      </div>
    </Slider>
  </div>

  </div> */}

  <Testimonial />


    {/* <div
      id="frameTitle"
      className="embedded-widget-title"
    >
      Book Now
    </div>
    <div
      className="vagaro"
    >
      
      <script
        type="text/javascript"
        src="https://www.vagaro.com//resources/WidgetEmbeddedLoader/OZqoC30rDJWcT3qmV35y6RuSdBuOc1WJD1wOc1WO61Ctdg4tjxMG9pUxapkUcXCu7gevEhAJDXwOW?v=29IBHha5IjgyoFRNl84FkGU4AnU4NyAUTxWZQqMG2T5#"
      ></script>
    </div> */}

    <Helmet>
    <div
      id="frameTitle"
      className="embedded-widget-title" >
      Book Now
    </div>
    <div
      className="vagaro">
      <script
        type="text/javascript"
        src="https://www.vagaro.com//resources/WidgetEmbeddedLoader/OZqoC30rDJWcT3qmV35y6RuSdBuOc1WJD1wOc1WO61Ctdg4tjxMG9pUxapkUcXCu7gevEhAJDXwOW?v=29IBHha5IjgyoFRNl84FkGU4AnU4NyAUTxWZQqMG2T5#"
      ></script>
    </div>
    </Helmet>

    </>
  );
} 