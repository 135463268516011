import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';
import Faq from '../FAQ/Faq';
import Landing from '../Landing/Landing';
// import Booking from '../Booking/Booking';
import Services from "../Services/Services";
import About from "../About/About";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
// import Floater from "../Floater/Floater";



function MainComp() {
  
  return (
    <React.StrictMode>
      <Navbar/>
      <Router>
        {/* <Floater/> */}
        <Switch>
          <Route exact path="/">
            <Landing/>
          </Route>
          {/* <Route exact path="/bookings">
            <Booking/>
          </Route> */}
          <Route exact path="/about">
            <About/>
          </Route>
          <Route exact path='/services'>
            <Services/>
          </Route>
          <Route exact path='/faq'>
            <Faq/>
          </Route>
          <Route exact path='/privacy'>
            <PrivacyPolicy/>
          </Route>
        </Switch>
      </Router>
      <Footer/>
   
    </React.StrictMode>
    )
}

export default MainComp
