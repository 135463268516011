import React from "react";
import "./footer.scss";
import logo from "../../assets/logoNew.png";
import insta from "../../assets/instagram.svg";
import call from "../../assets/call.svg";
import { FaLocationArrow, FaMessage, FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
// import arrow from "../../assets/arrowLeftB.svg";

function Footer() {
  return (

    <div className="footer">
      <div className="footCon">
        <div className="foot_links">
          <div className="linksLeft">
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/about">About Us</a>
              </li>
              <li>
                <a href="/services">Services</a>
              </li>
             
            </ul>
          </div>
          <div className="linksCenter1">
            <ul>
              <li>
            
                <a href="https://www.fresha.com/book-now/body-contouring-zuy26omc/all-offer?pId=659672">Book Now</a>
              

              </li>
              <li>
                <a href="https://g.page/LajeenArtistry?share">Find Us</a>
              </li>
              <li>
                <a href="/faq">FAQ</a>
              </li>
            </ul>

          </div>
          <div className="linksCenter2">
            <ul>
              <li>
                <a href="/">Terms Of Use</a>
              </li>
              <li>
                <a href="/privacy">Privacy Policy</a>
              </li>
            </ul>
          </div>
          <div className="linksRight">
            <img src={logo} style={{ height: "auto", width: "160px" }} alt="" />
          </div>

          <div className="extraCon">
            <div className="socialHandles">
            
              <div className="footerIcons">
              <a href="https://www.instagram.com/bodycontouring.au/?hl=en"> <img src={insta} height="30px" width="30px" alt="" />{" "}</a>                
              <a href="tel:+61404616832"><img src={call} height="30px" width="30px" alt="call" /></a>
              </div>
            </div>
            <div className="contactInfo">
							<ul>
								<li className='d-flex column-gap-2'><FaLocationArrow size={25} className="mx-2" />1470-1474 Sydney Rd, Australia</li>
								<li className='d-flex column-gap-2'><MdEmail size={20} className="mx-2" />hello@tagcw.com.au</li>
								<li className='d-flex column-gap-2'><FaPhone size={20} className="mx-2" />+61 410 910 644</li>
							</ul>
            </div>
          </div>
        </div>

        <p className="pwd"><a href="https://rmarketingm8.com/" target="_blank" rel="noopener noreferrer">Powered by MarketingM8</a></p>

        {/* <div className="footBtnCon">
          <div className="footBtn">
            <button type="button">
              <img src={arrow} alt="arrow" />
              Go to Lajeen Artistry
            </button>
          </div>
        </div> */}
      </div>



      <div className="footConM">
        <div className="foot_links">
        <div className="linksTop">
            <img src={logo} style={{ height: "100px", width: "auto" }} alt="" />
            
          <div className="extraCon">
            <div className="socialHandles">
            
              <div className="footerIcons">
                <a href="https://www.instagram.com/bodycontouring.au/?hl=en"> <img src={insta} height="30px" width="30px" alt="" />{" "}</a>
                <a href="tel:+61404616832"><img src={call} height="30px" width="30px" alt="call" /></a>
              </div>
            </div>
          </div>
          </div>
          <ul>
                <li>Body Contouring</li>
            </ul>
      <div className="footBottom">
          <div className="linksLeft">
            
            <ul>
              <li>  
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/about">About Us</a>
              </li>
              <li>
                <a href="/services">Services</a>
              </li>
              <li>
                <a href="/faq">FAQ</a>
              </li>
            </ul>
          </div>
          <div className="linksRight">
            <ul>
              <li>
                <a href="https://www.fresha.com/book-now/body-contouring-zuy26omc/all-offer?pId=659672">Book Now</a>
              </li>
              <li>
                <a href="https://g.page/LajeenArtistry?share">Find Us</a>
              </li>
              <li>
                <a href="/">Terms Of Use</a>
              </li>
              <li>
                <a href="/">Privacy Policy</a>
              </li>
            </ul>
          </div>
          </div>
        </div>

        <p className="pwd"><a href="https://rmarketingm8.com/" target="_blank" rel="noopener noreferrer">Powered by MarketingM8</a></p>
        
      {/* <div className="footBtnConM">
          <div className="footBtnM">
            <button type="button">
              <img src={arrow} alt="arrow" />
              Go to Lajeen Artistry
            </button>
          </div>
        </div> */}
        
      </div>

    </div>
  );
}

export default Footer;
