// import Faq from 'react-faq-component';
import Accordion from "react-bootstrap/Accordion";
import "bootstrap/dist/css/bootstrap.min.css";
import './faq.scss';


function Faq (){

    return (
      <div>
        <div style={{ paddingTop: "125px" }}>
        <h1 className="faqHead">Frequently Asked Questions</h1>

          {/* <Faq data={data} styles={styles}/> */}
          <Accordion defaultActiveKey=" ">
            <Accordion.Item eventKey="0">
              <Accordion.Header><span>Am I fit for fat-freezing?</span></Accordion.Header>
              <Accordion.Body>
              If you’re close to your target weight with no medical surgery done in the recent past, then you can qualify for fat freezing. Additionally, you should avoid this procedure if you are pregnant.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header><span>How much does it cost?</span></Accordion.Header>
              <Accordion.Body>
              The cost of the procedure varies depending on the area you wish to get treated. You can check out our Services section for the various packages that we offer. 
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header><span>Is there any pre and post-care I need to take before the procedure?</span></Accordion.Header>
              <Accordion.Body>
              Before and after the treatment, we advise you to drink plenty of water, have a balanced diet and exercise regularly. We recommend avoiding anti-inflammatory products before the procedure. 
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header><span>How long does it take?</span></Accordion.Header>
              <Accordion.Body>
              It depends on which part of your body you wish to treat. After consultation, we will be able to determine how many sessions you would require. Each session may be anywhere between 30 and 60 minutes.
              </Accordion.Body>
            </Accordion.Item> <Accordion.Item eventKey="4">
              <Accordion.Header><span>Is this permanent?</span></Accordion.Header>
              <Accordion.Body>
              Yes! Absolutely. Fat freezing permanently kills almost 20% of the fat cells in the treated region. 
              </Accordion.Body>
            </Accordion.Item> <Accordion.Item eventKey="5">
              <Accordion.Header><span>Is fat-freezing painful?</span></Accordion.Header>
              <Accordion.Body>
              You might experience some tingling, numbness or soreness in the treated area. However, you do not have to worry, as this pain is temporary. You will be able to resume your day-to-day chores immediately after the procedure. 
              </Accordion.Body>
            </Accordion.Item> <Accordion.Item eventKey="6">
              <Accordion.Header><span>What are the risks?</span></Accordion.Header>
              <Accordion.Body>
              Cryolipolysis or fat-freezing is a low risk, non-invasive procedure. It does not cause any damage to the surrounding tissues and nerves. 
              </Accordion.Body>
            </Accordion.Item> <Accordion.Item eventKey="7">
              <Accordion.Header><span>Will I be given anaesthesia?</span></Accordion.Header>
              <Accordion.Body>
              Not at all. Cryolipolysis is a non-invasive procedure and does not require anaesthesia. 
              </Accordion.Body>
            </Accordion.Item> <Accordion.Item eventKey="8">
              <Accordion.Header><span>Will I lose weight?</span></Accordion.Header>
              <Accordion.Body>
              Maybe not, but you will lose fat. Cryolipolysis is not a weight reduction procedure. It only helps you kill the fat cells. It reduces the capability of the body to store extra fat.
              </Accordion.Body>
            </Accordion.Item> <Accordion.Item eventKey="9">
              <Accordion.Header><span>Which area of my body can I treat?</span></Accordion.Header>
              <Accordion.Body>
              Cryolipolysis is an effective low-risk treatment performed on your chin, upper arms, inner and outer thighs, abdomen, hip/flank, upper back, lower back, and underneath the buttocks. 
              </Accordion.Body>
            </Accordion.Item> <Accordion.Item eventKey="10">
              <Accordion.Header><span>Are there any side effects of cryolipolysis?</span></Accordion.Header>
              <Accordion.Body>
              There are no long-lasting side effects of cryolipolysis. However, you may experience temporary soreness in the treated area due to the cold temperatures.
              </Accordion.Body>
            </Accordion.Item> <Accordion.Item eventKey="11">
              <Accordion.Header><span>How is cryolipolysis different from liposuction?</span></Accordion.Header>
              <Accordion.Body>
              Liposuction is an invasive procedure to remove large chunks of fat. However, cryolipolysis is a non-invasive procedure targeted at smaller parts of the body. 
              </Accordion.Body>
            </Accordion.Item> <Accordion.Item eventKey="12">
              <Accordion.Header><span>Can I do cryolipolysis after liposuction?</span></Accordion.Header>
              <Accordion.Body>
              Yes, of course. Cryolipolysis is effective to smooth any irregularities caused due to liposuction. It can help contour these areas permanently. 
              </Accordion.Body>
            </Accordion.Item> <Accordion.Item eventKey="13">
              <Accordion.Header><span>How long before I see results?</span></Accordion.Header>
              <Accordion.Body>
              You may experience the change within 3-4 weeks of the procedure. Your body will continue to flush out fat cells for the next 4-6 months.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <a href="tel:+61404616832">
            <div id="faqsBtn">
              <span>Unsure? Call Us</span>
            </div>
          </a>
      </div>
    </div>
    );
  }

export default Faq;
