import React from 'react';  
import './navbar.scss';
import logo from '../../assets/logoNew.png';
// import arrowLeft from '../../assets/arrowLeft.svg';
import bag from '../../assets/call.svg';


function Navbar() {
    return (

    <div id="navWrapper">
      <div className="goBack">
        <div className="left">
          <span style={{ margin: '1em 0.7em',cursor: 'pointer' }}> 
            {/* <img style= {{ marginRight: "0.7em"}} src={arrowLeft} alt="arrow1"/>
              <a href="https://lajeen.com.au/lajeenHome.html" style= {{ color: "#8D3A56"}}>Take me to Lajeen</a> */}
            </span>
        </div>
        <div className="right">
       
        <a id='deskNum' href='tel:+61404616832'>0404616832</a>
        </div>
      </div>

    
    <div id="navHover">
        {/* Left links  */}
  
          <div id="navLeftLinks">
            <nav className="navbar navbar-expand-sm  navbar-static-top navbar-light">
              <div className='mobBtnWrapper'>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar2">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <a id='mobileNum' href='tel:+61404616832' style={{cursor: 'pointer'}}><img src={bag} alt="call"></img></a>
              </div>
              <div className="navbar-collapse collapse" id="navbar2">
                  <ul className="navbar-nav">
                    <li id="homeId" className="nav-item active">
                        <a className="nav-link" style={{ marginRight: '2em' }} href="/">Home<span className="sr-only">Home</span></a>
                    </li>
                    <li id="beSecTouched" className="nav-item">
                        <a className="nav-link" style={{ marginRight: '0em' }} href="/about" id="aboutusNav">About Us</a>
                    </li>
                    <span id="navMobile"><li className="nav-item">
                    <a className="nav-link" style={{ marginRight: '2em' }} href="/services">Services</a>
                    </li>
                    
                    <li className="nav-item">
                        <a className="nav-link"  href="/faq">Faq</a>
                    </li>
                    {/* <li className="nav-item" id="bookBtnM">
                      <a className="nav-link" href="/bookings" style={{ color:'white' }}>Book Now</a>
                    </li> */}

                  </span>
                  </ul>
                </div>
              </nav>
            </div>

            <div  className="logoSectionM" >
            <a href="/">
              <img id="noScrollM" className="logoMobile" height="70em" width="auto" src={logo} alt=""/>
            </a>
            <a href="/">
              <img id="hoverImgM" className="logoMobile" height="70em" width="auto" src={logo} alt="" />
            </a>
            </div>
          
          {/* Main Logo */}
          <div className="logoSection">
          <a href="/">

            <img id="noScroll" className="logoDesktop" height="120rem" style={{transform:"translateY(-10px)"}} src={logo} alt=""/>
            </a>
            <a href="/">

            <img id="hoverImg" className="logoDesktop" height="120rem" src={logo} alt=""/>
            </a>
          </div>

          {/* Right links */}
        
          <div className="navCon">
            <nav className="navbar navbar-expand-sm  navbar-static-top navbar-light">
                <div className="navbar-collapse collapse" id="navbar2">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" style={{ marginRight: '2em' }} href="/services">Services</a>
                    </li>
                    
                    <li className="nav-item">
                        <a className="nav-link"  href="/faq">FAQ</a>
                    </li>
                  </ul>
                </div>
            </nav>
            {/* <a href="/bookings" style={{ textDecoration:"none", color:"white"}}><div id="bookBtn"><span>Book Now</span></div></a> */}
          </div>      
      </div>
    </div>
      
    )
}

export default Navbar
